<script>
export default {
  name: "ButtonAnswersTemplate",

  props: {
    questionText: {
      type: String,
      required: false
    },
    answers: {
      type: Array,
      required: true
    },
    buttonAction: {
      type: Function,
      required: true
    },
    gridButtonStyle: {
      type: Boolean,
      required: false
    }
  },
}
</script>

<template>
  <div v-if="questionText" class="row button-answers-row">
    <div class="box button-answers-box">
      <h2 class="main-heading button-answers-heading">{{questionText}}</h2>
    </div>
  </div>
  <slot></slot>
  <div class="row button-answers-row">
    <div class="box button-answers-box" :class="{'grid-button-arrangement' : gridButtonStyle}">
      <button
        class="main-button main-button-w mt-sm button-answers-button"
        v-for="answer in answers"
        @click="buttonAction(answer)"
      >
        {{answer}}
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/base-components/button-answers-template.scss';
</style>