<script>
import { useStore } from 'vuex'
import { onMounted } from 'vue'

import ButtonAnswersTemplate from "@/components/shared/base-components/ButtonAnswersTemplate"

const stepName = 'HospitalizedDueToAnxiety'

const ANSWERS = [
  'Yes',
  'No'
]

export default {
  name: stepName,

  emits: ['next-step'],

  components: {
    ButtonAnswersTemplate
  },

  setup(_, {emit}) {
    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })
    const store = useStore()

    const setAnswer = (userInput) => {
      store.commit('SET_FIELD', {field: 'hospitalized_due_to_anxiety', value: userInput.toLowerCase()})
      emit('next-step', stepName)
    }

    return {
      ANSWERS,
      setAnswer
    }
  }
}
</script>

<template>
  <div class="question-container">
    <div class="wrapper">
      <button-answers-template
        :button-action="setAnswer"
        :answers="ANSWERS"
      >
        <slot></slot>
      </button-answers-template>
    </div>
  </div>
</template>